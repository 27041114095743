import React from 'react';

import { Col, Row, notification } from 'antd';

import CustomerMapTable from './CustomerMapTable';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const CustomerMap = () => {
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: React.ReactNode
  ) => {
    api[type]({
      message: title,
      description,
    });
  };

  return (
    <Row gutter={16}>
      {contextHolder}
      <Col span={24}>
        <CustomerMapTable openNotificationWithIcon={openNotificationWithIcon} />
      </Col>
    </Row>
  );
};

export default CustomerMap;
