import React from 'react';

import { Button, Popconfirm } from 'antd';

import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { CustomerMapType } from '../../models/CustomerMap';
import { isEmpty } from '../../utils/helpers';

type ExportCSVProps = {
  fileName: string;
  filters: string;
  isDeleted: string;
};

const ExportCSV = ({ fileName, filters, isDeleted }: ExportCSVProps) => {
  const axiosPrivate = useAxiosPrivate();
  const downloadCSV = async () => {
    try {
      const response = await axiosPrivate.get(
        `/customer-map/export?isdeleted=${isDeleted}&${
          filters ? `${filters}` : ''
        }`
      );
      const csvData = await response.data;
      const csvURL = URL.createObjectURL(
        new Blob([csvData], { type: 'text/csv' })
      );
      const link = document.createElement('a');
      link.href = csvURL;
      link.download = `${fileName}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  return (
    <Button type="primary" className="px-4" onClick={downloadCSV}>
      Export
    </Button>
  );
};

const TableActions = ({
  filters,
  isDeleted,
  handleAdd,
  newRecord,
  entryCount,
  handleSyncConfirm,
}: /* handleAdd,
    entryCount,
    handleSyncConfirm, */
{
  filters: string;
  isDeleted: string;
  handleAdd: () => void;
  newRecord: CustomerMapType;
  entryCount: number;
  handleSyncConfirm: () => void;
}) => {
  return (
    <div className="flex gap-3 justify-between">
      <Button
        type="primary"
        className="flex items-center justify-between gap-1 px-4"
        onClick={() => handleAdd()}
        disabled={!isEmpty(newRecord)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
        <span>Add</span>
      </Button>
      <ExportCSV
        fileName={`${new Date().getTime()}_customer_map`}
        filters={filters}
        isDeleted={isDeleted}
      />
      <Popconfirm
        placement="topRight"
        disabled={entryCount === 0}
        title={
          <span>
            There is unsynced data. Please confirm by pressing&nbsp;
            <br />
            <b>YES</b> or <b>NO</b> to sync on platform?
          </span>
        }
        onConfirm={() => handleSyncConfirm()}
        okText="Yes"
        cancelText="No"
      >
        <Button
          type="primary"
          className={entryCount === 0 ? '' : 'px-4'}
          disabled={entryCount === 0}
        >
          Sync {entryCount === 0 ? '' : `(${entryCount})`}
        </Button>
      </Popconfirm>
    </div>
  );
};

export default TableActions;
