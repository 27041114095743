import { axiosPrivate } from '../api/axios';
import { type CustomerMapType } from '../models/CustomerMap';

export default class AuditTrailService {
  static async getAuditLog(
    page: number,
    size: number,
    auditRecord: CustomerMapType,
    filters?: string
  ) {
    return axiosPrivate({
      url: `/audit-log/customer-map?customerNbr=${
        auditRecord?.customerNbr
      }&productId=${auditRecord?.productId}&assetClass=${
        auditRecord?.assetClass
      }&serviceNbr=${auditRecord?.serviceId.serviceNbr}${
        filters ? `&${filters}` : ''
      }`,
      method: 'GET',
      params: {
        page,
        size,
      },
    });
  }
}
