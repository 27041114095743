export const SliamUploaderConstants = {
  SEARCH_FIELDS: [
    { id: 'progressStatus', value: 'Progress Status' },
    { id: 'fileType', value: 'File Type' },
  ],
  STATUS_DROPDOWNS: [
    { id: 'FilePersisted', value: 'Accepted' },
    { id: 'NonStarted', value: 'Non Started' },
    { id: 'InProgress', value: 'In Progress' },
    { id: 'InProcessQueue', value: 'In Queue' },
    { id: 'Completed', value: 'Completed' },
    { id: 'Aborted', value: 'Aborted' },
    { id: 'Paused', value: 'Paused' },
    { id: 'Failed', value: 'Failed' },
  ],
  TYPE_DROPDOWNS: [
    { id: 'ServiceCoverage', value: 'Coverage Map' },
    { id: 'PIDMaster', value: 'Product Id Map' },
    { id: 'ServiceType', value: 'Service Type' },
    { id: 'AssetClassServiceMap', value: 'Asset Class Service Map' },
    { id: 'customer_site', value: 'Customer Site' },
    { id: 'asset_ib_extract', value: 'Asset IB Extract' },
    { id: 'service_contract', value: 'Service Contract' },
    { id: 'asset_geotag_scan', value: 'Asset GeoTag Scan' },
    { id: 'auto_rule_job', value: 'Auto Rule Job' },
  ],
  PROGRESS_STATUS: 'progressStatus',
  FILE_TYPE: 'fileType',
  STATUS: {
    FILE_PERSISTED: 'FilePersisted',
    NON_STARTED: 'NonStarted',
    IN_PROGRESS: 'InProgress',
    IN_PROCESS_QUEUE: 'InProcessQueue',
    COMPLETED: 'Completed',
    ABORTED: 'Aborted',
    FAILED: 'Failed',
    PAUSED: 'Paused',
  },
  NO_BATCH_JOB_TO_PROCESS: 'NoBatchJobToProcess',
  BATCH_JOB_IN_PROCESS: 'BatchJobInProcess',
  BATCH_JOB_IN_PIPLINE: 'BatchJobInPipeline',
  FILE_TYPE_COUNT: 7,
};
