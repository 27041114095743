import { axiosPrivate } from '../api/axios';
import { AssetType } from '../models/AssetType';

export default class AssetService {
  static async getAssets(
    page: number,
    size: number,
    filters?: string,
    customerId?: string,
    isCustomer?: boolean
  ) {
    return axiosPrivate({
      url: `/assets?${isCustomer ? `customerId=${customerId}&` : ''}${
        filters ? `${filters}` : ''
      }`,
      method: 'GET',
      params: {
        page,
        size,
      },
    });
  }

  static async getAssetById(id: string) {
    return axiosPrivate({
      url: `/assets/${id}`,
      method: 'GET',
    });
  }

  static async createAsset(asset: AssetType) {
    return axiosPrivate({
      url: `/assets`,
      method: 'POST',
      data: asset,
    });
  }

  static async updateAsset(assetId: string, asset: AssetType) {
    return axiosPrivate({
      url: `/assets/${assetId}`,
      method: 'PUT',
      data: asset,
    });
  }

  static async deleteAsset(id: string) {
    return axiosPrivate({
      url: `/assets/${id}`,
      method: 'DELETE',
    });
  }

  static async retireAsset(id: string) {
    return axiosPrivate({
      url: `/assets/${id}/retire`,
      method: 'PATCH',
    });
  }

  static async getPendingItems(id: string, page: number, size: number) {
    return axiosPrivate({
      url: `/assets/${id}/pending-action`,
      method: 'GET',
      params: {
        page,
        size,
      },
    });
  }

  static async getAssetHistory(
    id: string,
    page: number,
    size: number,
    filters?: string
  ) {
    return axiosPrivate({
      url: `/assets/${id}/history?include=all${filters ? `&${filters}` : ''}`,
      method: 'GET',
      params: {
        page,
        size,
      },
    });
  }

  static async performAudit() {
    return axiosPrivate({
      url: `/assets/perform-audit`,
      method: 'GET',
    });
  }

  static async getAssetServiceMapping(
    assetClass?: string,
    id?: string,
    productId?: string
  ) {
    return axiosPrivate({
      url: `/assets/asset-class-services-map?customerNbr=${id}&assetClass=${assetClass}&productId=${productId}`,
      method: 'GET',
    });
  }
}
