import React from 'react';

import { CloseCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

type ClearFilterProps = {
  clearFilters: () => void;
};

const ClearFilter = ({ clearFilters }: ClearFilterProps) => {
  return (
    <Button type="primary" size="small" onClick={() => clearFilters()}>
      <span>Clear Filter</span>
      <CloseCircleOutlined />
    </Button>
  );
};

export default ClearFilter;
