/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';

import {
  CloseCircleOutlined,
  DeleteFilled,
  EditFilled,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Checkbox,
  AutoComplete,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { ColumnsType } from 'antd/es/table';
import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import AuditTrailModal from './AuditTrailModal';
import DataFilters from './DataFilters';
import TableActions from './TableActions';
import CustomerMapService from '../../APIServices/CustomerMapService';
import { type CustomerMapType } from '../../models/CustomerMap';
import * as ROUTES from '../../Routes/Routes';
import {
  checkSearchKeyExists,
  generateSearchQuery,
  isEmpty,
} from '../../utils/helpers';

type CustomerMapTableProps = {
  openNotificationWithIcon: any;
};

const FilterDropdown = (
  props: any,
  dataIndex: string,
  title: string,
  setMultipleFilters: React.Dispatch<
    React.SetStateAction<MultiFilters[] | null>
  >,
  multipleFilters: MultiFilters[] | null
) => {
  const [searchInput, setSearchInput] = useState('');
  const [blankProductId, setBlankProductId] = useState(false);
  const { close } = props;

  const handleOnChange = () => {
    // encodeURIComponent(e.target.value.trim());

    setMultipleFilters((prevState) => {
      if (searchInput !== '') {
        return prevState
          ? [...prevState, { searchKey: dataIndex, searchValue: searchInput }]
          : [{ searchKey: dataIndex, searchValue: searchInput }];
      }
      if (searchInput === '' && prevState) {
        const updatedFilters = prevState?.filter(
          (record) => record.searchKey !== dataIndex
        );
        return updatedFilters.length > 0 ? updatedFilters : null;
      }
      return prevState;
    });
    close();
  };

  useEffect(() => {
    if (multipleFilters && multipleFilters.length > 0) {
      const exist = multipleFilters.findIndex(
        (element) => element.searchKey === dataIndex
      );
      if (exist > -1) {
        setSearchInput('');
      }
      if (exist === -1 && dataIndex === 'productId') {
        setBlankProductId(false);
      }
    } else {
      setSearchInput('');
      setBlankProductId(false);
    }
  }, [multipleFilters]);

  const onProductIdEmptyChange = (e: CheckboxChangeEvent) => {
    setMultipleFilters((prevState) => {
      return prevState
        ? [...prevState, { searchKey: 'hasProductId', searchValue: 'false' }]
        : [{ searchKey: 'hasProductId', searchValue: 'false' }];
    });
    setBlankProductId(true);
    close();
  };
  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      {dataIndex === 'productId' && (
        <Checkbox
          checked={blankProductId}
          onChange={onProductIdEmptyChange}
          style={{ padding: 8 }}
          disabled={searchInput !== ''}
        >
          Show blank PID entries
        </Checkbox>
      )}
      <Input
        placeholder={`Search ${title}`}
        className="my-1 flex"
        allowClear
        prefix={<SearchOutlined />}
        disabled={checkSearchKeyExists(dataIndex, multipleFilters)}
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      <Button
        type="primary"
        onClick={() => handleOnChange()}
        size="small"
        style={{ width: '100%' }}
        disabled={checkSearchKeyExists(dataIndex, multipleFilters)}
      >
        Search
      </Button>
    </div>
  );
};

type MultiFilters = {
  searchKey: string;
  searchValue: string;
};

const CustomerMapTable = ({
  openNotificationWithIcon,
}: CustomerMapTableProps) => {
  const [auditTrail, setAuditTrail] = useState(false);
  const updateMutation = useMutation(CustomerMapService.update);
  const deleteMutation = useMutation(CustomerMapService.delete);
  const addMutation = useMutation(CustomerMapService.add);
  const syncMutation = useMutation(CustomerMapService.postAutomationRule);
  const [isDeleted, setIsDeleted] = useState('false');
  const [auditRecord, setAuditRecord] = useState<CustomerMapType>(null!);
  const [newRecord, setNewRecord] = useState<CustomerMapType>(
    {} as CustomerMapType
  );
  const [entryCount, setEntryCount] = useState(0);
  const [customerMapData, setCustomerMapData] = useState<CustomerMapType[]>(
    [] as CustomerMapType[]
  );

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [filters, setFilters] = useState('');
  const [multipleFilters, setMultipleFilters] = useState<MultiFilters[] | null>(
    null
  );
  const [validationsErr, setValidationErr] = useState<{
    [key: string]: string;
  }>();
  // validations
  const customerMapValidation = z.object({
    id: z.string().optional(),
    assetClass: z.string().nonempty({ message: 'Asset class is required' }),
    serviceId: z.object({
      serviceNbr: z.string().nonempty({ message: 'Service ID is required' }),
    }),
    serviceDescription: z
      .string()
      .nonempty({ message: 'Service Description is required' }),
    annualRate: z
      .number({ message: 'Annual Rate must be a positive number' })
      .nonnegative({ message: 'Annual Rate must be a positive number' })
      .gt(0, { message: 'Annual Rate should be greater than zero' }),
    coverage: z.string().nonempty({ message: 'Coverage is required' }),
    customerNbr: z.string().nonempty({ message: 'Customer Nbr is required' }),
    quoteNbr: z.string().nonempty({ message: 'Quote Nbr is required' }),
  });

  const handleValidation = (formObjectValues: CustomerMapType) => {
    const validationResult = customerMapValidation.safeParse(formObjectValues);

    const invalidFields: { [key: string]: string } = {};
    if (!validationResult.success && validationResult.error) {
      Object.keys(validationResult.error.format()).forEach((key) => {
        const formattedError = validationResult.error.format();
        const error = formattedError[key as keyof typeof formattedError];
        if (error && '_errors' in error) {
          // eslint-disable-next-line no-underscore-dangle
          invalidFields[key] = error._errors.join(', ');
        }
        if (error && 'serviceNbr' in error) {
          if (error.serviceNbr) {
            // eslint-disable-next-line no-underscore-dangle
            invalidFields.serviceNbr = error.serviceNbr._errors.join(', ');
          }
        }
      });
    }
    setValidationErr(invalidFields);
    if (isEmpty(invalidFields)) {
      return true;
    }
    return false;
  };

  const handleValidationUpdate = (key: string) => {
    setValidationErr((prevData) => {
      const newData = { ...prevData };
      delete newData[key];
      return newData;
    });
  };

  // fetch data
  const {
    data: customerMap,
    isLoading,
    refetch,
  } = useQuery(['customer-map', page, pageSize, isDeleted, filters], () =>
    CustomerMapService.getCustomerMap(page, pageSize, isDeleted, filters)
  );

  const { data: customerMapLookup } = useQuery(['customer-map-lookup'], () =>
    CustomerMapService.getCustomerMapLookup()
  );

  React.useEffect(() => {
    if (customerMap?.data) {
      setCustomerMapData(customerMap.data);
    }
  }, [customerMap]);

  const tableRefresh = () => {
    setPage(0);
    setPageSize(10);
  };

  const handleMultiSearch = () => {
    tableRefresh();
    multipleFilters && setFilters(generateSearchQuery(multipleFilters));
  };

  useEffect(() => {
    if (multipleFilters) {
      handleMultiSearch();
    } else {
      setFilters('');
    }
  }, [multipleFilters]);

  const handleChange = (pagination: any) => {
    setPage(pagination.current - 1);
    setPageSize(pagination.pageSize);
  };

  const confirmDelete = (record: CustomerMapType) => {
    deleteMutation.mutate(record, {
      onSuccess: () => {
        refetch();
        openNotificationWithIcon(
          'success',
          'Success',
          'Customer map entry successfully deleted'
        );
      },
      onError: (error) => {
        const axiosError = error as AxiosError;
        return openNotificationWithIcon(
          'error',
          'Error',
          `Failed to delete customer map entry: ${axiosError.message}`
        );
      },
    });
  };

  const handleAdd = () => {
    setValidationErr({});
    const newEntry = {
      id: '',
      assetClass: '',
      productId: '',
      serviceId: {
        serviceNbr: '',
        serviceType: '',
      },
      serviceDescription: '',
      annualRate: 0,
      coverage: '',
      customerNbr: '',
      quoteNbr: '',
    };

    setNewRecord(newEntry);
    setCustomerMapData([newEntry, ...customerMapData]);
  };

  const handleValueChange = (value: string, key: string) => {
    /* if (key.includes('.')) {
      const [parentKey, childKey] = key.split('.');
      // eslint-disable-next-line no-prototype-builtins
      if (Object.prototype.hasOwnProperty.call(newRecord, parentKey)) {
        setNewRecord({
          ...newRecord,
          [parentKey]: {
            ...newRecord[parentKey],
            [childKey]: value,
          },
        });
      } else {
        setNewRecord({
          ...newRecord,
          [parentKey]: { ...newRecord[parentKey], [childKey]: value },
        });
      }
    } else {
      setNewRecord({ ...newRecord, [key]: value });
    } */

    if (key !== 'serviceNbr') {
      setNewRecord({
        ...newRecord,
        [key]: key === 'annualRate' ? parseInt(value, 10) : value,
      });
    }
    if (key === 'serviceNbr') {
      const serviceType = value
        ? customerMapLookup?.data.serviceTypes[value][0]
        : '';
      setNewRecord({
        ...newRecord,
        serviceId: { serviceNbr: value, serviceType },
      });
    }

    handleValidationUpdate(key);
  };

  const handleSave = () => {
    if (!handleValidation(newRecord)) {
      return;
    }
    addMutation.mutate(
      { ...newRecord, createdBy: 'ncr-admin', updatedBy: 'ncr-admin' },
      {
        onSuccess: () => {
          setNewRecord({} as CustomerMapType);
          refetch();
          setEntryCount(entryCount + 1);
          openNotificationWithIcon(
            'success',
            'Success',
            'Customer map entry successfully added'
          );
        },
        onError: (error) => {
          const axiosError = error as AxiosError;
          return openNotificationWithIcon(
            'error',
            'Error',
            `Failed to update customer map entry: ${axiosError.response?.data?.message}`
          );
        },
      }
    );
  };

  const handleUpdate = (id: string) => {
    if (!handleValidation(newRecord)) {
      return;
    }
    updateMutation.mutate(
      { ...newRecord, createdBy: 'ncr-admin', updatedBy: 'ncr-admin' },
      {
        onSuccess: () => {
          setNewRecord({} as CustomerMapType);
          refetch();
          setEntryCount(entryCount + 1);
          openNotificationWithIcon(
            'success',
            'Success',
            'Customer map entry successfully updated'
          );
        },
        onError: (error) => {
          const axiosError = error as AxiosError;
          return openNotificationWithIcon(
            'error',
            'Error',
            `Failed to update customer map entry: ${axiosError.response?.data?.message}`
          );
        },
      }
    );
  };

  const handleCancel = () => {
    setNewRecord({} as CustomerMapType);
    setValidationErr({});
    const customerMapRow = customerMapData.filter((row) => row.id !== '');
    setCustomerMapData(customerMapRow);
  };

  const handleSyncConfirm = () => {
    syncMutation.mutate(true, {
      onSuccess: () => {
        refetch();
        setEntryCount(0);
        openNotificationWithIcon(
          'success',
          'Success',
          <span>
            Customer map data successfully synced. Please visit{' '}
            <b>Data Management</b> page to see the progress of sync
          </span>
        );
      },
      onError: (error) => {
        const axiosError = error as AxiosError;
        return openNotificationWithIcon(
          'error',
          'Error',
          `Failed to sync customer map entry: ${axiosError.response?.data?.message}`
        );
      },
    });
  };

  const handleAuditLog = (id: string) => {
    const customerMapRow = customerMap?.data.find(
      (row: CustomerMapType) => row.id === id
    );
    setAuditRecord(customerMapRow);
    setAuditTrail(true);
  };

  const clearFilters = () => {
    tableRefresh();
    setFilters('');
  };

  const handleClearSingleFilter = (searchKey: string) => {
    const updatedFilters = multipleFilters?.filter(
      (record) => record.searchKey !== searchKey
    );
    tableRefresh();
    setMultipleFilters(updatedFilters?.length ? updatedFilters : null);
  };

  const handleClearAll = () => {
    tableRefresh();
    setMultipleFilters(null);
  };

  // columns for the table
  const columns: ColumnsType<CustomerMapType> = [
    {
      title: 'Class (First 4 digit of Prod ID)',
      key: 'assetClass',
      filterDropdown: (props) =>
        FilterDropdown(
          props,
          'assetClass',
          'Class (First 4 digit of Prod ID)',
          setMultipleFilters,
          multipleFilters
        ),
      filterSearch: true,
      width: '10%',
      render: (record: CustomerMapType) =>
        !record.assetClass || newRecord?.id === record.id ? (
          <Form.Item style={{ margin: '-5px', width: 150 }}>
            <Input
              status={validationsErr?.assetClass ? 'error' : ''}
              placeholder="Enter Class"
              defaultValue={newRecord.assetClass}
              onChange={(e) => handleValueChange(e.target.value, 'assetClass')}
            />
            {validationsErr?.assetClass && (
              <span className="text-red-500 text-xs">
                {validationsErr.assetClass}
              </span>
            )}
          </Form.Item>
        ) : (
          <div>{record.assetClass}</div>
        ),
      // ),
    },
    {
      title: 'Product ID',
      key: 'productId',
      filterDropdown: (props) =>
        FilterDropdown(
          props,
          'productId',
          'Product ID',
          setMultipleFilters,
          multipleFilters
        ),
      filterSearch: true,
      render: (record: CustomerMapType) =>
        (!record.assetClass && !record.productId) ||
        newRecord?.id === record.id ? (
          <Form.Item style={{ margin: '-5px', width: 160 }}>
            <AutoComplete
              options={Array.from(
                customerMapLookup?.data?.productIds || [],
                (x) => ({ label: x, value: x })
              )}
              filterOption
              notFoundContent="No suggestions available"
              placeholder="Enter Product ID"
              defaultValue={newRecord.productId}
              getPopupContainer={(trigger) => trigger.parentElement}
              onChange={(value) => handleValueChange(value, 'productId')}
            />
            {/*  <Input
              status={validationsErr?.productId ? 'error' : ''}
              placeholder="Enter Product ID"
              defaultValue={record.productId}
              onChange={(e) => handleValueChange('productId', e.target.value)}
            /> */}
            {validationsErr?.productId && (
              <span className="text-red-500 text-xs">
                {validationsErr.productId}
              </span>
            )}
          </Form.Item>
        ) : (
          <div className="whitespace-nowrap">{record.productId}</div>
        ),
    },
    {
      title: 'Service ID',
      key: 'serviceId',
      filterDropdown: (props) =>
        FilterDropdown(
          props,
          'serviceId',
          'Service ID',
          setMultipleFilters,
          multipleFilters
        ),
      filterSearch: true,
      render: (record: CustomerMapType) =>
        !record.serviceId?.serviceNbr || newRecord?.id === record.id ? (
          <Form.Item style={{ margin: '-5px', width: 160 }}>
            <AutoComplete
              filterOption
              notFoundContent="No suggestions available"
              options={Array.from(
                customerMapLookup?.data?.serviceNbrs || [],
                (x) => ({ label: x, value: x })
              )}
              placeholder="Enter Service ID"
              defaultValue={newRecord.serviceId.serviceNbr}
              getPopupContainer={(trigger) => trigger.parentElement}
              onChange={(value) => handleValueChange(value, 'serviceNbr')}
            />
            {/* <Input
              status={validationsErr?.serviceNbr ? 'error' : ''}
              placeholder="Enter Service ID"
              defaultValue={record.serviceId.serviceNbr}
              onChange={(e) =>
                handleValueChange('serviceId.serviceNbr', e.target.value)
              }
            /> */}
            {validationsErr?.serviceNbr && (
              <span className="text-red-500 text-xs">
                {validationsErr.serviceNbr}
              </span>
            )}
          </Form.Item>
        ) : (
          <div className="whitespace-nowrap">{record.serviceId.serviceNbr}</div>
        ),
    },
    {
      title: 'Service Description',
      key: 'serviceDescription',
      render: (record: CustomerMapType) =>
        !record.serviceDescription || newRecord?.id === record.id ? (
          <Form.Item style={{ margin: '-5px', width: 200 }}>
            <Input
              status={validationsErr?.serviceDescription ? 'error' : ''}
              placeholder="Enter Service Description"
              defaultValue={newRecord.serviceDescription}
              onChange={(e) =>
                handleValueChange(e.target.value, 'serviceDescription')
              }
            />
            {validationsErr?.serviceDescription && (
              <span className="text-red-500 text-xs">
                {validationsErr.serviceDescription}
              </span>
            )}
          </Form.Item>
        ) : (
          <div>{record.serviceDescription}</div>
        ),
    },
    {
      title: 'Annual Rate',
      key: 'annualRate',
      render: (record: CustomerMapType) =>
        !record.annualRate || newRecord?.id === record.id ? (
          <Form.Item style={{ margin: '-5px', width: 160 }}>
            <Input
              type="number"
              status={validationsErr?.annualRate ? 'error' : ''}
              placeholder="Enter Annual Rate"
              defaultValue={newRecord.annualRate}
              onChange={(e) => handleValueChange(e.target.value, 'annualRate')}
            />
            {validationsErr?.annualRate && (
              <span className="text-red-500 text-xs">
                {validationsErr.annualRate}
              </span>
            )}
          </Form.Item>
        ) : (
          <div className="text-right">{record.annualRate}</div>
        ),
    },
    {
      title: 'Coverage',
      key: 'coverage',
      filterDropdown: (props) =>
        FilterDropdown(
          props,
          'coverage',
          'Coverage',
          setMultipleFilters,
          multipleFilters
        ),
      filterSearch: true,
      render: (record: CustomerMapType) =>
        !record.coverage || newRecord?.id === record.id ? (
          <Form.Item style={{ margin: '-5px', width: 160 }}>
            <AutoComplete
              status={validationsErr?.coverage ? 'error' : ''}
              filterOption
              options={Array.from(
                customerMapLookup?.data?.coverageCodes || [],
                (x) => ({ label: x, value: x })
              )}
              placeholder="Enter Coverage"
              notFoundContent="No suggestions available"
              getPopupContainer={(trigger) => trigger.parentElement}
              onChange={(value) => handleValueChange(value, 'coverage')}
              defaultValue={newRecord.coverage}
            />
            {/* <Input
              status={validationsErr?.coverage ? 'error' : ''}
              placeholder="Enter Coverage"
              defaultValue={record.coverage}
              onChange={(e) => handleValueChange('coverage', e.target.value)}
            /> */}
            {validationsErr?.coverage && (
              <span className="text-red-500 text-xs">
                {validationsErr.coverage}
              </span>
            )}
          </Form.Item>
        ) : (
          <div className="text-right">{record.coverage}</div>
        ),
    },
    {
      title: 'Customer Nbr',
      key: 'customerNbr',
      filterDropdown: (props) =>
        FilterDropdown(
          props,
          'customerNbr',
          'Customer Number',
          setMultipleFilters,
          multipleFilters
        ),
      filterSearch: true,
      render: (record: CustomerMapType) =>
        !record.customerNbr || newRecord?.id === record.id ? (
          <Form.Item
            style={{
              margin: '-5px',
              width: 160,
            }}
          >
            <Input
              status={validationsErr?.customerNbr ? 'error' : ''}
              placeholder="Enter Customer Number"
              defaultValue={newRecord.customerNbr}
              onChange={(e) => handleValueChange(e.target.value, 'customerNbr')}
            />
            {validationsErr?.customerNbr && (
              <span className="text-red-500 text-xs">
                {validationsErr.customerNbr}
              </span>
            )}
          </Form.Item>
        ) : (
          <div className="text-right">{record.customerNbr}</div>
        ),
    },
    {
      title: 'Quote Nbr',
      key: 'quoteNbr',
      filterDropdown: (props) =>
        FilterDropdown(
          props,
          'quoteNbr',
          'Quote Number',
          setMultipleFilters,
          multipleFilters
        ),
      filterSearch: true,
      render: (record: CustomerMapType) =>
        !record.quoteNbr || newRecord?.id === record.id ? (
          <Form.Item style={{ margin: '-5px', width: 150 }}>
            <Input
              status={validationsErr?.quoteNbr ? 'error' : ''}
              placeholder="Enter Quote Number"
              defaultValue={newRecord.quoteNbr}
              onChange={(e) => handleValueChange(e.target.value, 'quoteNbr')}
            />
            {validationsErr?.quoteNbr && (
              <span className="text-red-500 text-xs">
                {validationsErr.quoteNbr}
              </span>
            )}
          </Form.Item>
        ) : (
          <div className="text-right whitespace-nowrap">{record.quoteNbr}</div>
        ),
    },
    {
      title: 'Service Type',
      key: 'serviceType',
      filterDropdown: (props) =>
        FilterDropdown(
          props,
          'serviceType',
          'Service Type',
          setMultipleFilters,
          multipleFilters
        ),
      filterSearch: true,
      render: (record: CustomerMapType) =>
        !record.serviceId.serviceType || newRecord?.id === record.id ? (
          <Form.Item style={{ margin: '-5px', width: 150 }}>
            <Input
              status={validationsErr?.serviceType ? 'error' : ''}
              placeholder="Autofill based on Service ID"
              value={newRecord.serviceId.serviceType}
              readOnly
            />
          </Form.Item>
        ) : (
          <div className="text-right">{record.serviceId.serviceType}</div>
        ),
    },
    {
      title: 'Actions',
      render: (record: CustomerMapType) => {
        return !record.id || newRecord?.id === record.id ? (
          <Space size="middle" align="center">
            <Button
              type="primary"
              size="small"
              onClick={
                newRecord?.id ? () => handleUpdate(newRecord.id!) : handleSave
              }
            >
              {newRecord?.id ? 'Update' : 'Save'}
            </Button>
            <Button type="text" size="small" onClick={handleCancel}>
              Cancel
            </Button>
          </Space>
        ) : (
          <Space size="middle" align="center">
            <Button
              type="text"
              size="small"
              onClick={() => handleAuditLog(record.id!)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path d="M11.644 1.59a.75.75 0 0 1 .712 0l9.75 5.25a.75.75 0 0 1 0 1.32l-9.75 5.25a.75.75 0 0 1-.712 0l-9.75-5.25a.75.75 0 0 1 0-1.32l9.75-5.25Z" />
                <path d="m3.265 10.602 7.668 4.129a2.25 2.25 0 0 0 2.134 0l7.668-4.13 1.37.739a.75.75 0 0 1 0 1.32l-9.75 5.25a.75.75 0 0 1-.71 0l-9.75-5.25a.75.75 0 0 1 0-1.32l1.37-.738Z" />
                <path d="m10.933 19.231-7.668-4.13-1.37.739a.75.75 0 0 0 0 1.32l9.75 5.25c.221.12.489.12.71 0l9.75-5.25a.75.75 0 0 0 0-1.32l-1.37-.738-7.668 4.13a2.25 2.25 0 0 1-2.134-.001Z" />
              </svg>
            </Button>
            <Button
              type="text"
              size="small"
              disabled={!isEmpty(newRecord)}
              onClick={() => setNewRecord(record)}
            >
              <EditFilled />
            </Button>
            <div>
              <Popconfirm
                placement="topRight"
                title="Are you sure to delete this entry?"
                onConfirm={() => confirmDelete(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" size="small" disabled={!isEmpty(newRecord)}>
                  <DeleteFilled />
                </Button>
              </Popconfirm>
            </div>
          </Space>
        );
      },
      key: 'actions',
    },
  ];

  console.log(entryCount);

  return (
    <>
      <Row className="h-full">
        <Col span={24}>
          <Card
            title="Customer Map"
            className="h-full"
            extra={
              <TableActions
                filters={filters}
                isDeleted={isDeleted}
                newRecord={newRecord}
                handleAdd={handleAdd}
                entryCount={entryCount}
                handleSyncConfirm={handleSyncConfirm}
                /* handleAdd={handleAdd}
                entryCount={entryCount}
                handleSyncConfirm={handleSyncConfirm} */
              />
            }
          >
            <div className="flex justify-end">
              <DataFilters
                tableRefresh={tableRefresh}
                clearFilters={clearFilters}
                setIsDeleted={setIsDeleted}
              />
            </div>

            <div className="flex justify-start items-center pt-3 gap-4 h-auto">
              <div className="flex gap-2 justify-start items-center">
                {multipleFilters &&
                  multipleFilters.map((filter) => {
                    return (
                      <Button
                        type="link"
                        key={filter.searchKey}
                        className="border border-ncr-brand-dark-teal rounded-lg hover:border-ncr-brand-dark-teal"
                      >
                        <span className="capitalize text-sm">
                          {filter.searchKey === 'assetClass' && 'Class'}
                          {filter.searchKey === 'hasProductId' && 'ProductId'}
                          {filter.searchKey !== 'assetClass' &&
                            filter.searchKey !== 'hasProductId' &&
                            filter.searchKey}
                          :
                        </span>
                        <span className="pl-2 capitalize font-semibold">
                          {filter.searchValue === 'false'
                            ? 'Blank'
                            : filter.searchValue}
                        </span>
                        <CloseCircleOutlined
                          onClick={() =>
                            handleClearSingleFilter(filter.searchKey)
                          }
                        />
                      </Button>
                    );
                  })}
              </div>
              <div className="flex gap-2 self-center">
                {multipleFilters && multipleFilters.length > 1 && (
                  <Button type="primary" onClick={handleClearAll}>
                    <span className="capitalize font-semibold text-xs">
                      Clear All
                    </span>
                    <CloseCircleOutlined onClick={() => handleClearAll()} />
                  </Button>
                )}
              </div>
            </div>

            <div className="customer-map-table-wrapper pt-3">
              <Table
                columns={columns}
                rowKey={(record) => record.id!}
                dataSource={customerMapData}
                loading={isLoading}
                onChange={handleChange}
                locale={{
                  emptyText: <Empty description="No customer map data" />,
                }}
                className="customer-map-table"
                id="customer-map-table"
                size="large"
                pagination={{
                  defaultPageSize: 10,
                  current: page + 1,
                  pageSize,
                  total: Number(customerMap?.headers['x-total-count']),
                  pageSizeOptions: ['10', '20', '50', '100'],
                  showSizeChanger: true,
                }}
                getPopupContainer={() =>
                  document.querySelector(
                    '.customer-map-table-wrapper'
                  ) as HTMLElement
                }
              />
            </div>
          </Card>
        </Col>
      </Row>
      <AuditTrailModal
        auditTrail={auditTrail}
        setAuditTrail={setAuditTrail}
        auditRecord={auditRecord}
        searchFields={[
          { id: 'coverage', value: 'Coverage' },
          { id: 'quoteNbr', value: 'Quote Number' },
          { id: 'executionStatus', value: 'Execution Status' },
          { id: 'uploadedBy', value: 'Uploaded By' },
          { id: 'uploadedAt', value: 'Uploaded At' },
        ]}
      />
    </>
  );
};

export default CustomerMapTable;
