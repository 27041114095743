/**
 * MAIN ROUTES
 */
export const DASHBOARD_NCR = '/ncr';
export const CLIENT_SEARCH = 'client-search';
export const CLIENT_PROFILE = 'customers/:id';
export const CLIENT_OVERVIEW = 'overview';
export const CLIENT_PROFILE_ASSETS = 'assets';
export const CLIENT_PROFILE_ASSETS_SINGLE = 'assets/:id';
export const CLIENT_PROFILE_CONTRACTS = 'contracts';
export const CLIENT_PROFILE_CONTRACTS_SINGLE = 'contracts/:id';
export const CLIENT_SITES = 'sites';
export const CLIENT_SINGLE_SITE = 'sites/:id';
export const CUSTOMERS = 'customers';
export const CONTRACTS = 'contracts';
export const SINGLE_CONTRACT = 'contracts/:id';
export const SINGLE_CONTRACT_PENDING_TASKS = 'contracts/:id/pending-tasks';
export const SINGLE_CONTRACT_SERVICES = 'contracts/:id/services';
export const ASSETS = 'assets';
export const USER_PROFILE = 'profile';
export const USER_PROFILE_DATA_MANAGEMENT = 'data-management';
export const ASSETS_SINGLE = 'assets/:id';
export const DATA_MANAGEMENT = 'data-management';

export const DASHBOARD_NCR_CLIENT = '/dashboard';
export const CLIENT_ASSETS = 'assets';
export const CLIENT_CONTRACTS = 'contracts';
export const CLIENT_CONTRACTS_SINGLE = 'contracts/:id';
export const CLIENT_SERVICES = 'services';
export const CLIENT_USER_PROFILE = 'profile';
export const SINGLE_CLIENT_ASSETS = 'assets/:id';
export const SITES = 'sites';
export const SINGLE_SITE = 'sites/:id';

export const CUSTOMER_MAP = 'customer-map';
